import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { CURRENCY, IPlayerSegmentation, IUser } from '@libs/interfaces';

@Injectable({
  providedIn: 'root',
})
export class SegmentationService {
  constructor(private api: ApiService) {}
  getGeoref(ip: string): Observable<any> {
    return this.api.post(`georef`, { ip });
  }
  updateUserSegmentation(user_id: string): Promise<IPlayerSegmentation> {
    return this.api
      .post(`segmentation/update/user`, { user_id })
      .pipe(map((res: IUser) => res.segmentation))
      .toPromise();
  }
}
